"use strict";

(function () {
  var cn = 'd3f6e4a5';
  if (!document.cookie.split('; ').find(function (row) {
    return row.startsWith("".concat(cn, "="));
  })) {
    var referrer = document.referrer;
    var referrerHost = new URL(document.referrer).hostname;
    var currentHost = window.location.hostname;
    if (referrer && referrerHost !== currentHost) {
      document.cookie = "".concat(cn, "=").concat(encodeURIComponent(btoa(referrer)), "; path=/; max-age=").concat(60 * 60 * 24 * 7, "; SameSite=Lax");
    }
  }
})();

// jQuery free